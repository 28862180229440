<template>
    <div>
        <h3 class="desc">{{ $t('feedback.desc') }}</h3>
        <el-input type="textarea" :rows="4" :placeholder="$t('feedback.desc-ph')" v-model="message"> </el-input>
        <h3 class="desc">{{ $t('feedback.additonal') }}</h3>
        <Dropzone ref="dzone"> </Dropzone>
    </div>
</template>

<script>
import { browserInfoMixin } from '@/mixins/browserInfo';

import { sendFeedback } from '@/api/feedback';

import { EventBus } from '@/utils/Bus.js';

import { mapGetters } from 'vuex';
const DropzoneComp = () => import('@/components/Dropzone/Index.vue');

export default {
    name: 'basicFeedback',
    mixins: [browserInfoMixin],
    components: {
        Dropzone: DropzoneComp
    },
    data() {
        return {
            message: ''
        };
    },
    methods: {
        async sendFeedback() {
            const form = new FormData();
            if (typeof this.browserDetails !== 'undefined' && Object.keys(this.browserDetails).length > 0) {
                form.append('browserinfo', JSON.stringify(this.browserDetails));
            }
            if (this.message.length < 5) {
                this.sbMsg({
                    message: this.$t('feedback.desc-error'),
                    type: 'error'
                });
                return false;
            }
            form.append('message', this.message);
            form.append('isFeedback', true);
            form.append('action', 'sendFeedback');
            form.append('userEmail', this.userEmail);
            form.append('userId', this.userId);
            form.append('userName', this.userName);
            const secondaryImages = this.$refs.dzone.$refs.dropzoneComp.getAcceptedFiles();
            for (const i in secondaryImages) {
                form.append(`file-${i}`, secondaryImages[i]);
            }

            const req = await sendFeedback({
                form,
                isFeedback: true
            });
            if (typeof req.error === 'undefined' || typeof req.message === 'undefined') {
                this.sbMsg({
                    message: this.$t('feedback.feedback-error'),
                    type: 'error'
                });
            }
            if (req.error == 1) {
                this.sbMsg({
                    message: req.message,
                    type: 'error'
                });
            } else {
                this.sbMsg({
                    type: 'success',
                    message: this.$t('feedback.feedback-sent')
                });
            }
            EventBus.$emit('destroyFeedback');
            return true;
        }
    },
    computed: {
        ...mapGetters({
            userEmail: 'user/userEmail',
            userId: 'user/userId',
            userName: 'user/userName'
        })
    },
    mounted() {
        EventBus.$on('sendbasicfeedback', (data) => {
            this.sendFeedback();
        });
    },
    created() {},
    beforeDestroy() {
        EventBus.$off();
    }
};
</script>

<style scoped lang="scss">
h3.desc:first-child {
    margin-top: 0;
}
</style>
